import React from 'react';

import Container from '../components/Container';
import Layout from '../components/Layout/Layout';

import * as styles from './contact.module.css';
const AboutPage = (props) => {

  return (
    <Layout>
      <div className={styles.root}>
      <Container size={'large'}>
        <div className={styles.content}>
          <h3>Contact Me</h3>
          <p className={styles.paragraph}>
            I like meeting new people, hearing feedback and supporting cool projects.<br></br>
            If you'd like to connect with me, I'd love to hear from you.<br></br>
            Please reach out via my email below:<br></br><br></br>
            kyle@morethanmainstream.com
          </p>
        </div>
      </Container>
      </div>
    </Layout>
  );
};

export default AboutPage;
